.user-name {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}
.user-company {
  color: var(--character-secondary-45, rgba(8, 42, 81, 0.5));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}
.profile-pic-wrapper {
  width: 70px;
  margin-right: 10px;
}

.profile-pic-wrapper img {
  width: 100%;
}
.subject-content {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.message-content {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
