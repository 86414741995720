.board-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.page{
    width: 793px;
    height: 1122px;
    border: solid 1px #ccc;
}
.section-heading{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    text-align: left;
    margin: 0 0 10px;
    padding: 0;
}
.input-field-wrapper{
    position: relative;
}
.input-field-wrapper label {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #082a51;
    display: block;
    margin: 0 0 5px;
    padding: 0;
}

.input-field-wrapper input[type="text"]{
    width: 100%;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    background: #eee;
    padding: 8px 12px;
    margin: 0;
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
}
.text-content h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    text-align: left;
    margin: 0 0 10px;
    padding: 0;
}
.table-head-cell{
    font-weight: bold;
    color: #000;
    padding: 8px 5px;
}
.table-body-cell{
    padding: 8px 5px;
}
.upload-label{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #082a51;
    display: block;
    margin: 0 0 5px;
    padding: 0;
}
.upload-empty {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 74px;
    cursor: pointer;
}
.uploaded{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1890ff;
    width: calc(100% - 35px);
}
.upload-filled {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* height: 74px; */
    /* padding: 9px 8px; */
}
.upload-box {
    border-radius: 2px;
    border: 1px solid #d9d9d9;
}