.remove-pdf-button {
  background: none;
  border: none;
  width: auto;
}
.upload-pdf-label {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 5px 10px;
}
.uploaded-pdf-label {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-left: 10px;
}
.form-label {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.form-input {
  padding: 5px;
  border-radius: 6px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
}
