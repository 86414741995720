@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  background-color: #f0f5ff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #595959;
  margin: 0;
  padding: 0;
}

a,
a:hover,
a:visited {
  text-decoration: none;
  color: inherit;
}

a,
a:after,
a:before,
button,
.button {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

*:focus,
*:focus-visible {
  outline: none;
  box-shadow: none;
}

img,
a img {
  display: block;
  max-width: 100%;
  height: auto;
}

.clearfix:before,
.clearfix:after,
ul:before,
ul:after {
  content: "";
  display: table;
}

.clearfix:after,
ul:after {
  clear: both;
}

.clearfix,
ul {
  zoom: 1;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.container {
  max-width: 1280px;
}

.landingWrapper {
  margin: 120px 0;
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 0px 34px 44px 0px rgba(0, 39, 102, 0.09);
  overflow: hidden;
}

.landingRow {
  display: flex;
  flex-direction: row;
}

.landingCol {
  width: 50%;
  height: 800px;
}

.landingWrapper .fieldsWrapper {
  margin: 0;
  padding: 85px 130px;
}

.landingWrapper .fieldsWrapper .logo {
  margin: 0 0 60px;
}

.landingWrapper .fieldsWrapper h2 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #082a51;
  margin: 0 0 20px;
  padding: 0;
}

.landingWrapper .fieldsWrapper p {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #595959;
  margin: 0 0 40px;
  padding: 0;
}

.landingWrapper .fieldsWrapper p a {
  color: #096dd9;
}

.landingWrapper .gb-login-form {
  margin: 0;
  padding: 0;
  min-height: 324px;
}

.landingWrapper .gb-login-form .gb-field-wrap {
  margin: 0 0 20px;
  position: relative;
}

.landingWrapper .gb-login-form .gb-field-wrap label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
  margin: 0 0 10px;
  padding: 0;
  display: block;
}

.landingWrapper .gb-login-form .gb-field-wrap .form-class {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  padding: 8px 12px;
  margin: 0;
  display: block;
  width: 100%;
}

.landingWrapper .gb-login-form .gb-field-wrap .iconField {
  position: absolute;
  bottom: 10px;
  right: 12px;
  width: 20px;
  height: 20px;
}

.landingWrapper .gb-login-form .gb-form-footer .form-submit {
  border: 1px solid #1890ff;
  border-radius: 6px;
  background-color: #114df2;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 8px 12px;
  margin: 0;
  display: block;
  width: 100%;
}

.landingWrapper .gb-login-form .gb-form-footer .form-submit:hover {
  border-color: #000;
  background-color: #1f1f1f;
}

.landingWrapper .gb-login-form .form-check {
  margin: 0 0 20px;
}

.landingWrapper .gb-login-form .form-check-label {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.landingWrapper .gb-login-form .form-check-label a {
  font-weight: 500;
  color: #114df2;
}

.landingWrapper .gb-login-form .forget-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #114df2;
  text-decoration: underline;
  margin: 0 15px;
}

.landingWrapper .gb-step-list {
  margin: 45px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.landingWrapper .gb-step-list span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  margin: 0;
  background-color: #e6f7ff;
  cursor: pointer;
}

.landingWrapper .gb-step-list span.current {
  background-color: #114df2;
}

.landingWrapper .gb-step-list span + span {
  margin-left: 20px;
}

.landingWrapper .imageWrapper {
  background-color: #d6e4ff;
  height: 100%;
  padding: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.landingWrapper .imageWrapper .booksAnimation {
  margin: 0 auto;
}

.landingWrapper .imageWrapper h3 {
  font-weight: 300;
  font-size: 38px;
  line-height: 110%;
  text-align: center;
  margin: -90px auto 0;
  padding: 0;
  clear: both;
}

.landingWrapper .imageWrapper h3 strong {
  display: block;
  font-weight: 700;
  font-size: 25px;
}

.landingWrapper .imageWrapper .loginBox {
  border-radius: 6px;
  border: 1px dashed #114df2;
  background-color: #fff;
  padding: 6.4px 15px;
  margin: 0;
  position: absolute;
  top: 45px;
  right: 45px;
}

.landingWrapper .imageWrapper .loginBox p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #114df2;
  margin: 0;
  padding: 0;
}

.landingWrapper .imageWrapper .loginBox a {
  font-weight: 700;
  color: #114df2;
  text-decoration: underline;
  margin: 0 0 0 10px;
}

.landingWrapper .imageWrapper .loginBox:hover {
  border-color: #1f1f1f;
  color: #1f1f1f;
}

.landingWrapper .imageWrapper .loginBox:hover p,
.landingWrapper .imageWrapper .loginBox:hover p a {
  color: #1f1f1f;
}

.landingWrapper .lp-pwMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 90px 0;
}

.landingWrapper .lp-pwWrapper {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.landingWrapper .lp-pwMain .logo {
  margin: 0 auto 60px;
}

.landingWrapper .lp-pwMain h2 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #082a51;
  text-align: center;
  margin: 0 0 20px;
  padding: 0;
}

.landingWrapper .lp-pwMain p {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #595959;
  text-align: center;
  margin: 0 0 40px;
  padding: 0;
}

.landingWrapper .lp-pwMain p a {
  color: #096dd9;
}

.landingWrapper .lp-pwMain .button-login {
  border-radius: 6px;
  border: 1px dashed #114df2;
  background-color: #fff;
  padding: 6.4px 15px;
  margin: 0 auto;
  display: table;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #114df2;
}

.landingWrapper .lp-pwMain .button-login:hover {
  border-color: #1f1f1f;
  color: #1f1f1f;
}

.landingWrapper .lp-pwMain .gb-login-form {
  min-height: inherit;
  margin-bottom: 40px;
}

.navbar {
  width: 256px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.navbar .navHead {
  width: 100%;
}

.navbar .logo {
  width: auto;
  height: 40px;
  margin: 12px 0 12px 16px;
}

.navbar .navHead ul {
  margin: 0;
  padding: 0;
}

.navbar .navHead ul > li {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
}

.navbar .navHead ul > li + li {
  margin-top: 4px;
}

.navbar .navHead ul > li > a,
.navbar .navHead ul > li.collapsed > .navBlock > a {
  padding: 9px 0;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
  width: 100%;
}

.navbar .navHead ul > li > a > img,
.navbar .navHead ul > li.collapsed > .navBlock > a > img {
  margin: 0 10px 0 0;
}

.navbar .navHead ul > li.collapsed {
  display: flex;
  flex-direction: column;
}

.navbar .navHead ul > li.collapsed > .navBlock > a {
  font-weight: 500;
}

.navbar .navHead ul > li.collapsed > .navBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar .navHead ul > li.collapsed > img.iconToggle {
  margin: 0;
  cursor: pointer;
}

.navbar .navHead ul > li.active {
  background-color: #e6f7ff;
  box-shadow: -3px 0px 0px 0px #1890ff inset;
}

.navbar .navHead ul > li.active > a {
  font-weight: 600;
  color: #114df2;
}

.navbar .navHead ul > li.collapsed > ul {
  margin: 0;
  padding: 0;
}

.navbar .navHead ul > li.collapsed > ul > li {
  margin: 4px 0 0;
  padding: 0 0 0 24px;
  display: flex;
  justify-content: space-between;
}

.navbar .navHead ul > li.collapsed > ul > li > a {
  padding: 9px 0;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
}

.navbar .navFoot {
  border-top: 1px solid #bfbfbf;
  width: 100%;
  padding: 10px 16px 40px;
}

.navbar .navFoot a {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #ff4d4f;
  display: flex;
  padding: 9px 0;
}

.navbar .navFoot a img {
  margin: 0 10px 0 0;
}

.header {
  width: calc(100% - 256px);
  height: auto;
  background-color: #fff;
  box-shadow: 0px 5px 10px 0px rgba(19, 15, 38, 0.05);
  position: fixed;
  top: 0;
  left: 256px;
  z-index: 9999;
  margin: 0;
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.header .searchForm {
  width: 370px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header .searchForm input {
  background-color: transparent;
  border: none;
  width: calc(100% - 46px);
  padding: 0 0 0 10px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}

.header .searchForm input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.header .searchForm input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.header .searchForm input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.header .searchForm button {
  background-color: #114df2;
  border: none;
  border-radius: 6px;
  width: 30px;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header .notification {
  margin: 0 32px;
  padding: 0;
  position: relative;
  width: 24px;
  height: 24px;
}

.header .notification span {
  position: absolute;
  top: -8px;
  left: 12px;
  background-color: #f5222d;
  border-radius: 100px;
  padding: 0 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.header .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.wrapper {
  padding: 27px 22px;
  margin: 60px 0 0 256px;
}

.row01 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 40px;
}

.boxApp {
  width: 400px;
  margin: 0 20px 0 0;
}

.boxApp.w100 {
  width: 100%;
  margin: 0;
}

.boxApp .boxAppHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 20px;
}

.boxApp .boxAppHead h2 {
  margin: 0;
}

.boxApp.w100 .appStates {
  margin: 0 -10px;
}

.boxApp.w100 .appState {
  width: calc(20% - 20px);
  margin: 0 10px;
  padding: 20px 30px;
}

.boxApp .boxAppHead .buttonPrimary {
  border: 1px solid #1890ff;
  background: #114df2;
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  padding: 4px 15px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  text-align: center;
  display: table;
}

.boxForm {
  width: auto;
}

.boxApp h2,
.boxForm h2 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #082a51;
  margin: 0 0 20px;
  padding: 0;
}

.appStates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.appState {
  background: #fff;
  border-radius: 8px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  width: 190px;
  padding: 20px 30px;
  text-align: center;
  margin-right: 20px;
}

.appState:nth-child(n + 3) {
  /* margin-top: 20px; */
}

.appState h3 {
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.appState h3 img {
  margin: 0 5px 0 0;
}

.appState h4 {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  margin: 0 auto 14px;
  padding: 0 0 10px;
  display: table;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.appState a {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.appState.stateProcess {
  color: #13c2c2;
  border-bottom-color: #08979c;
  box-shadow: 0px 5px 10px 0px rgba(53, 195, 200, 0.15);
}

.appState.stateProcess h4 {
  border-bottom-color: #b5f5ec;
}

.appState.stateProcess a {
  color: #08979c;
}

.appState.stateProcess a:hover {
  color: #13c2c2;
}

.appState.statePending {
  color: #722ed1;
  border-bottom-color: #531dab;
  box-shadow: 0px 5px 10px 0px rgba(137, 79, 218, 0.2);
}

.appState.statePending h4 {
  border-bottom-color: #d3adf7;
}

.appState.statePending a {
  color: #531dab;
}

.appState.statePending a:hover {
  color: #722ed1;
}

.appState.stateDiscrepancies {
  color: #2f54eb;
  border-bottom-color: #1d39c4;
  box-shadow: 0px 5px 10px 0px rgba(112, 137, 240, 0.2);
}

.appState.stateDiscrepancies h4 {
  border-bottom-color: #adc6ff;
}

.appState.stateDiscrepancies a {
  color: #1d39c4;
}

.appState.stateDiscrepancies a:hover {
  color: #2f54eb;
}

.appState.stateApproved {
  color: #52c41a;
  border-bottom-color: #73d13d;
  box-shadow: 0px 5px 10px 0px rgba(82, 196, 26, 0.15);
}

.appState.stateApproved h4 {
  border-bottom-color: #d9f7be;
}

.appState.stateApproved a {
  color: #73d13d;
}

.appState.stateApproved a:hover {
  color: #52c41a;
}

.appState.stateRenewel {
  color: #fa8c16;
  border-bottom-color: #ff7a45;
  box-shadow: 0px 5px 10px 0px rgba(250, 140, 22, 0.1);
}

.appState.stateRenewel h4 {
  border-bottom-color: #ffe7ba;
}

.appState.stateRenewel a {
  color: #d46b08;
}

.appState.stateRenewel a:hover {
  color: #fa8c16;
}

.boxWhite {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 10px 0px rgba(51, 39, 107, 0.08);
  padding: 20px 0;
}

.boxWhite .searchForm {
  width: 370px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.boxWhite .searchForm input {
  background-color: transparent;
  border: none;
  width: calc(100% - 46px);
  padding: 0 0 0 10px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}

.boxWhite .searchForm input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.boxWhite .searchForm input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.boxWhite .searchForm input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.boxWhite .searchForm button {
  background-color: #114df2;
  border: none;
  border-radius: 6px;
  width: 30px;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.boardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 25px;
  padding: 0 30px;
}

.boardHeader.noMg {
  margin: 0;
}

.boardHeader h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #082a51;
  margin: 0;
  padding: 0;
}

.boardHeader .boardHeadRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.boardNoForms {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  padding: 100px 30px;
}

.boardNoForms img {
  width: 240px;
  margin: 0 auto 20px;
}

.boardNoForms p {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: rgba(8, 42, 81, 0.5);
  text-align: center;
  margin: 0;
  padding: 0;
}

.boardFilter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.boardFilter .form-check-inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  min-height: 22px;
}

.boardFilter label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.boardFilter .form-check-input {
  width: 16px;
  height: 16px;
  position: relative;
  float: none;
  margin: 3px 6px 0 0;
  padding: 0;
}

.boardFilter .form-check-input:checked {
  background-color: #114df2;
  border-color: #114df2;
}

.boardFilter .form-check-inline + .form-check-inline {
  margin-left: 16px;
}

.boardFilter + .searchForm {
  margin-left: 20px;
}

.boardThead,
.boardTrow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.boardTrow {
  border-bottom: 1px solid #eaecf0;
}

.boardTheadcompany,
.boardTrowcompany {
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.boardTrowcompany {
  border-bottom: 1px solid #eaecf0;
}

.boardTheadcompany {
  border-bottom: 1px solid #eaecf0;
  background: #f9fafb;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: rgba(8, 42, 81, 0.5);
}

.boardTheadcompany .boardCol {
  font-weight: 500;
  color: rgba(8, 42, 81, 0.5);
}

.boardnotification {
}

.boardnotification {
  border-bottom: 1px solid #eaecf0;
}

.boardThead {
  border-bottom: 1px solid #eaecf0;
  background: #f9fafb;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(8, 42, 81, 0.5);
}

.boardDue {
  background: #fff1f0;
}

.boardCol {
  padding: 12px 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #082a51;
}

.boardCol .dueDate {
  font-weight: 600;
  color: #f5222d;
}

.boardThead .boardCol {
  font-weight: 500;
  color: rgba(8, 42, 81, 0.5);
}

.boardCol.w-85 {
  width: 85px;
}

.boardCol.w-165 {
  width: 165px;
}

.boardCol.w-110 {
  width: 110px;
}

.boardCol.w-180 {
  width: 180px;
}

.boardCol.w-260 {
  width: 260px;
}

.boardCol.w-320 {
  width: 320px;
}

.boardCol.w-355 {
  width: 355px;
}

.boardCol.w-440 {
  width: 440px;
}

.boardCol.w-200 {
  width: 200px;
}

.boardCol.w-220 {
  width: 220px;
}

.boardCol.w-adj1 {
  width: calc(100% - 1020px);
}

.boardCol.w-adj2 {
  width: calc(100% - 1045px);
}

.boardCol.w-adj3 {
  width: calc(100% - 690px);
}

.boardCol.w-adj4 {
  width: calc(100% - 855px);
}

.boardCol.w-adj5 {
  width: calc(100% - 950px);
}

.boardCol.w-adj6 {
  width: calc(100% - 900px);
}

.boardCol.w-adj7 {
  width: calc(100% - 1005px);
}

.boardCol.w-adj8 {
  width: calc(100% - 970px);
}

.boardCol.w-adj9 {
  width: calc(100% - 1275px);
}

.boardCol.w-adj10 {
  width: calc(100% - 1130px);
}

.boardCol .boardTitle {
  margin: 0;
  position: relative;
}

.boardCol .boardTitle.widAvatar {
  padding: 0 0 0 42px;
}

.boardCol .boardTitle .avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 0;
}

.boardCol .boardTitle strong {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #082a51;
}

.boardCol .boardTitle span {
  display: block;
  font-size: 12px;
  color: rgba(8, 42, 81, 0.5);
}

.boardCol .boardTitle .userStatus {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 0;
}

.boardCol .boardTitle .userStatus .avatar {
  position: relative;
  top: 0;
}

.boardCol .boardTitle .userStatus .online,
.boardCol .boardTitle .userStatus .offline {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}

.boardCol .boardTitle .userStatus .offline {
  background: #d9d9d9;
}

.boardCol .boardTitle .userStatus .online {
  background: #52c41a;
}

.boardCol .boardButton {
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #fff;
  display: table;
}

.boardCol .boardButton.btnProcess {
  background-color: #13c2c2;
}

.boardCol .boardButton.btnPending {
  background-color: #722ed1;
}

.boardCol .boardButton.btnApproved {
  background-color: #52c41a;
}

.boardCol .boardButton.btnRejected {
  background-color: #eb2f96;
}

.boardCol .boardButton.btnDiscrepancies {
  background-color: #2f54eb;
}

.boardCol .boardButton.btnPendingFromDW {
  background-color: #2fe8eb;
}

.boardCol .boardButton.btnDrafts {
  background-color: #eb812f;
}

.boardCol .boardButton.btnRenewal {
  background-color: #962feb;
}

.boardCol .boardDownload,
.boardCol .boardActions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.boardCol .boardActions {
  justify-content: flex-end;
}

.boardCol .boardDownload a + a,
.boardCol .boardActions a + a {
  margin-left: 10px;
}

.boardFooter {
  margin: 25px 0 0;
  padding: 0 30px;
}

.boardFooter .pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.boardFooter .pagination,
.boardFooter .pagination a {
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #082a51;
  text-align: center;
}

.boardFooter .pagination a {
  width: 32px;
  height: 32px;
  border: 1px solid rgba(8, 42, 81, 0.5);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.boardFooter .pagination a.disabled {
  opacity: 0.4;
  cursor: default;
}

.boardFooter .pagination a.wAuto {
  width: auto;
  padding: 0 12px;
}

.boardFooter .pagination a.active {
  background-color: #114df2;
  border-color: #114df2;
  color: #fff;
}

.boardFooter .pagination a + a,
.boardFooter .pagination a + span {
  margin-left: 8px;
}

.boardFooter .pagination a .ml-6 {
  margin-left: 6px;
}

.boardFooter .pagination input {
  border: 1px solid rgba(8, 42, 81, 0.5);
  border-radius: 6px;
  width: 50px;
  height: 32px;
  padding: 0 12px;
  margin: 0 0 0 8px;
}

/* .empAdd .datepicker-container {
  width: 187%;
} */

.empAdd {
  margin: 30px 0;
  padding: 0 10px;
}

.empAdd h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #082a51;
  margin: 0 0 20px;
  padding: 0;
}

.empAdd h3.noMg {
  margin: 0;
}

.empAdd .fieldWrap {
  margin: 20px 0 0;
}

.empAdd .fieldWrap.noLabel {
  padding-top: 42px;
}

.empAdd .fieldWrap.noLabel.alongCheckbox {
  padding-top: 20px;
}

.empAdd label {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
  padding: 0;
  margin: 0 0 10px;
}

.empAdd input {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  width: 100%;
  /* max-width: 370px; */
  padding: 8px 12px;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.empAdd button {
  border-radius: 4px;
  border: 1px solid #1890ff;
  background: #114df2;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  padding: 4px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  text-align: center;
}

.empAdd .form-check-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.empAdd .form-check-inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  min-height: 22px;
}

.empAdd .form-check-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  margin: 0;
  padding: 0;
}

.empAdd .form-check-input {
  width: 16px;
  height: 16px;
  position: relative;
  float: none;
  margin: 3px 6px 0 0;
  padding: 0;
}

.empAdd .form-check-input:checked {
  background-color: #114df2;
  border-color: #114df2;
}

.empAdd .form-check-inline + .form-check-inline {
  margin-left: 16px;
}

.content-wrapper {
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
}

.profHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profHeader h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #082a51;
  margin: 0;
  padding: 0;
}

.profHeader .btngroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.profHeader .btngroup a + a {
  margin-left: 20px;
}

.profHeader .buttonPrimary,
.profHeader .buttonSecondary {
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  padding: 4px 15px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: table;
}

.profHeader .buttonPrimary {
  border: 1px solid #1890ff;
  background: #114df2;
  color: #fff;
}
.create-form-button {
  border-radius: 2px;
  background: var(--primary-10, #114df2);
  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  border: none;
  padding: 5px 10px;
}
.create-form-cancel-button {
  border-radius: 2px;
  border: dashed 1px #114df2;
  color: #114df2;
  background-color: #fff;
  /* background: var(--primary-10, #114df2); */
  /* drop-shadow/button-primary */
  /* box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04); */
  /* color: var(--character-primary-inverse, #fff); */
  text-align: center;
  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  /* border: none; */
  padding: 5px 10px;
}

.profHeader .buttonSecondary {
  background-color: #fff;
  border: 1px dashed #114df2;
  color: #114df2;
}

.divUpload {
  border-radius: 6px;
  border: 1px dashed rgba(8, 42, 81, 0.5);
  padding: 20px;
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.divnotification {
  border-radius: 6px;
  border-bottom: 1px dashed rgba(8, 42, 81, 0.5);
  padding: 10px;
  /* margin: 20px 0; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.divnotification .uploadLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divnotification .avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: 0 17px 0 0;
}

.divnotification p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  margin: 0;
}

.divnotification .btngroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.divnotification .btngroup a + a {
  margin-left: 20px;
}

.divnotification .buttonPrimary,
.divnotification .buttonSecondary {
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  padding: 4px 15px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: table;
}

.divnotification .buttonPrimary {
  background-color: #fff;
  border: 1px dashed #114df2;
  color: #114df2;
}

.divnotification .buttonSecondary {
  background-color: transparent;
  border: 1px dashed transparent;
  color: #114df2;
}

.divUpload .uploadLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divUpload .avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: 0 17px 0 0;
}

.divUpload p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  margin: 0;
}

.divUpload .btngroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.divUpload .btngroup a + a {
  margin-left: 20px;
}

.divUpload .buttonPrimary,
.divUpload .buttonSecondary {
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  padding: 4px 15px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: table;
}

.divUpload .buttonPrimary {
  background-color: #fff;
  border: 1px dashed #114df2;
  color: #114df2;
}

.divUpload .buttonSecondary {
  background-color: transparent;
  border: 1px dashed transparent;
  color: #114df2;
}

.profileAccord {
  margin: 0 0 30px;
}

.profileAccord .accordion-item {
  border-bottom: 0;
  background: none;
}

.profileAccord .accordion-item + .accordion-item {
  margin-top: 20px;
}

.profileAccord .accordion-item .accordion-header {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 8px 12px 0px rgba(51, 39, 107, 0.06);
  overflow: hidden;
}

.profileAccord .accordion-item .accordion-header .accordion-button {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #082a51;
}

.profileAccord .accordion-body {
  padding: 0;
  font-weight: 400;
}

.profileNotify {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
}

.feebackBox {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 30px;
}

.feebackBox h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #082a51;
  margin: 0 0 30px;
  padding: 0;
}

.feebackBox p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  margin: 0 0 20px;
  padding: 0;
}

.feebackBox p.description {
  font-weight: 700;
  text-transform: capitalize;
}

.feebackBox .fieldWrap {
  margin: 0 0 20px;
}

.feebackBox .fieldWrap.footerWrap {
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

.feebackBox label {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
  padding: 0;
  margin: 0 0 10px;
}

.feebackBox input,
.feebackBox textarea {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #fff;
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}

.feebackBox button {
  border-radius: 4px;
  border: 1px solid #1890ff;
  background: #114df2;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  padding: 4px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  text-align: center;
}

.alertProfile {
  border-radius: 2px;
  border: 1px solid #ffe58f;
  background: #fffbe6;
  margin: -27px -22px 30px;
  padding: 9px 16px;
}

.alertProfile,
.alertProfile p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.alertProfile img {
  margin: 0 10px 0 0;
}

.alertProfile p {
  display: flex;
  flex-direction: row;
  margin: 0 30px 0 0;
}

.alertProfile p a {
  font-weight: 700;
  color: #114df2;
  margin: 0 0 0 4px;
}

.alertProfile .btn-close {
  position: absolute;
  top: 9px;
  right: 16px;
  z-index: 2;
  padding: 0;
  font-size: 10px;
  line-height: 22px;
  display: block;
  width: 22px;
  height: 22px;
}

.progressWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.progressWrap .progress {
  width: calc(100% - 40px);
  height: 8px;
  border-radius: 100px;
  background-color: #f5f5f5;
}

.progressWrap .progress .progIssue {
  background-color: #ff4d4f;
}

.progressWrap .progress .progWorking {
  background-color: #114df2;
}

.progressWrap .progress .progDone {
  background-color: #52c41a;
}

.progressWrap .progressStatus {
  width: 30px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(8, 42, 81, 0.5);
}

.headFiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.headFiles .rightFiles {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.headFiles .addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #114df2;
  background: #114df2;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  padding: 4px 15px;
}

.headFiles .addButton img {
  margin: 0 8px 0 0;
}

.divFiles {
  margin: 30px 0;
  padding: 0 10px;
}

.divFiles h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #082a51;
  margin: 0 0 20px;
  padding: 0;
}

.divFiles h3.noMg {
  margin: 0;
}

.divFiles .rowFolders {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -9px;
}

.divFiles .colFolder {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 10px 0px rgba(51, 39, 107, 0.06);
  padding: 24px 10px;
  margin: 20px 9px 0;
  text-align: center;
  width: 170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.divFiles .colFolder .foldNav {
  position: absolute;
  top: 15px;
  right: 15px;
}

.divFiles .colFolder .foldLink {
  display: table;
  margin: 0 auto 15px;
}

.divFiles .colFolder h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #082a51;
  text-align: center;
  text-transform: capitalize;
  margin: 0 0 5px;
  padding: 0;
}

.divFiles .colFolder p {
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
  text-align: center;
  margin: 0;
  padding: 0;
}

.divFiles .rowAttachments {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -9px;
}

.divFiles .colAttachment {
  border-radius: 8px;
  background: #fff;
  margin: 20px 9px 0;
  text-align: center;
  width: 170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.divFiles .colAttachment.upload {
  border: 1px dashed #114df2;
  padding: 40px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #114df2;
  cursor: pointer;
}

.divFiles .colAttachment.upload img {
  margin: 0 auto 10px;
}

.divFiles .colAttachment.uploaded {
  padding: 5px;
  background: none;
  border: 1px solid #d9d9d9;
}

.divFiles .colAttachment.uploaded img {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.breadcrumbFiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #082a51;
}

.breadcrumbFiles .back {
  margin: 0 10px 0 0;
}

.breadcrumbFiles a,
.breadcrumbFiles span {
  color: rgba(0, 0, 0, 0.45);
}

.breadcrumbFiles span {
  margin: 0 8px;
}

.divFiles .rowCertifications {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -9px;
}

.divFiles .colCertification {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 10px 0px rgba(51, 39, 107, 0.06);
  padding: 24px 20px;
  margin: 20px 9px 0;
  text-align: center;
  width: 205px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.divFiles .colCertification img {
  width: 165px;
  height: 115px;
  border-radius: 6px;
  margin: 0 auto 15px;
  object-fit: cover;
}

.divFiles .colCertification h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #082a51;
  text-transform: capitalize;
  margin: 0 0 10px;
  padding: 0;
}

.divFiles .colCertification .issue {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
}

.divFiles .colCertification .issue span {
  font-weight: 600;
}

.divFiles .colCertification .issue {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #082a51;
}

.divFiles .colCertification .issue span {
  font-weight: 600;
  font-size: 14px;
}

.divFiles .colCertification .expire {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #082a51;
}

.divFiles .colCertification .expire span {
  font-weight: 600;
  font-size: 14px;
}

.divFiles .colCertification .expire span.due {
  color: #f5222d;
}

.boxAppForms.boxWhite {
  margin: 30px 0;
  padding: 0;
}

.boxAppForms ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.boxAppForms ul li {
  font-size: 16px;
  line-height: 24px;
  color: #082a51;
  margin: 0;
  padding: 0;
}

.boxAppForms ul li + li {
  margin-left: 10px;
}

.boxAppForms ul li a {
  padding: 16px 10px;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.boxAppForms ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  display: block;
  content: "";
  width: 100%;
  height: 2px;
}

.boxAppForms ul li a:hover:after {
  background-color: #082a51;
}

.boxAppForms ul li a span {
  border-radius: 100px;
  background: #f0f0f0;
  padding: 0 8px;
  margin: 0 0 0 4px;
  font-size: 12px;
  line-height: 20px;
  color: #434343;
  display: table;
}

.boxAppForms ul li a.active {
  color: #114df2;
}

.boxAppForms ul li a.active:after {
  background-color: #114df2;
}

.boxAppForms ul li a.active span {
  background-color: #e6f7ff;
  color: #114df2;
}

.divAppForms .rowAppForms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -16px;
}

.divAppForms .colAppForm {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 10px 0px rgba(51, 39, 107, 0.06);
  padding: 0;
  margin: 0 16px 32px;
  width: 300px;

  align-items: center;
  position: relative;
  text-align: center;
}

.divAppForms .colAppForm .AppFormWrap {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.divAppForms .colAppForm .AppFormWrap h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #082a51;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
}

.divAppForms .colAppForm .AppFormWrap p {
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
  margin: 8px 0;
}

.divAppForms .colAppForm .AppFormWrap .buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.divAppForms .colAppForm .AppFormWrap .buttonGroup a + a {
  margin-left: 10px;
}

.divAppForms .colAppForm .AppFormWrap .AppFormButton {
  background: #114df2;
  border: 1px solid #114df2;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  padding: 5px 15px;
  margin: 0 auto;
  display: table;
}

.divAppForms .colAppForm .AppFormWrap .AppFormButton:hover {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.divAppForms .colAppForm .AppFormWrap .AppFormButton2 {
  background: transparent;
  border: 1px dashed #114df2;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #114df2;
  padding: 5px 15px;
  margin: 0 auto;
  display: table;
}

.divAppForms .colAppForm .AppFormWrap .AppFormButton2:hover {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
}

.AppFormWrapper {
  background: #fff;
  border-radius: 8px;
  padding: 48px 32px 32px;
}

.AppFormWrapper h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #082a51;
  text-align: center;
  margin: 0 auto 10px;
  padding: 0;
}

.AppFormWrapper h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
  padding: 0;
}

.AppFormWrapper h6 b {
  font-weight: 700;
  color: #114df2;
}

.AppFormWrapper .stepBar {
  margin: 50px auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.AppFormWrapper .stepBar .step {
  padding: 26px 0 0;
  position: relative;
  z-index: 0;
}

.AppFormWrapper .stepBar .step + .step {
  margin-left: 30px;
}

.AppFormWrapper .stepBar .step:before {
  display: block;
  content: "";
  background-color: #f0f0f0;
  width: calc(100% + 30px);
  height: 2px;
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -2;
}

.AppFormWrapper .stepBar .step:after {
  display: block;
  content: "";
  background-color: #f0f0f0;
  width: 50%;
  height: 2px;
  position: absolute;
  top: 8px;
  left: 0;
  margin: auto;
  z-index: -1;
}

.AppFormWrapper .stepBar .step:first-child:before {
  width: calc(50% + 30px);
  right: -30px;
  left: inherit;
}

.AppFormWrapper .stepBar .step:last-child:before {
  width: calc(50% + 30px);
  left: -30px;
  right: inherit;
}

.AppFormWrapper .stepBar .step .dot {
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  width: 16px;
  height: 16px;
  display: block;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.25);
  border: 4px solid #fff;
  border-radius: 10px;
}

.AppFormWrapper .stepBar .step h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  margin: 0;
  padding: 0;
}

.AppFormWrapper .stepBar .step h4 b,
.AppFormWrapper .stepBar .step h4 span {
  display: block;
}

.AppFormWrapper .stepBar .step h4 b {
  font-weight: 500;
  margin-bottom: 2px;
}

.AppFormWrapper .stepBar .step.current h4 {
  color: #114df2;
}

.AppFormWrapper .stepBar .step.finished .dot,
.AppFormWrapper .stepBar .step.current .dot {
  background-color: #114df2;
  width: 18px;
  height: 18px;
  top: 0;
}

.AppFormWrapper .stepBar .step.finished h4 {
  color: #000;
}

.AppFormWrapper .stepBar .step.finished:before,
.AppFormWrapper .stepBar .step.finished:after {
  background-color: #114df2;
}

.AppFormWrapper .stepBar .step.current:after {
  background-color: #114df2;
}

.AppFormWrapper .stepBar .step:first-child.current:after,
.AppFormWrapper .stepBar .step:first-child.finished:after {
  display: none;
}

.AppFormWrapper .stepBar .step:last-child.current:after,
.AppFormWrapper .stepBar .step:last-child.finished:after {
  width: calc(50% + 30px);
  left: -30px;
}

.AppFormWrapper .stepBar.stepAdminBar {
  width: 100%;
  max-width: 960px;
  justify-content: space-between;
}

.AppFormWrapper .stepBar.stepAdminBar .step:before {
  width: calc(50% + 340px);
  left: 50%;
}

.AppFormWrapper .stepBar.stepAdminBar .step:first-child:before {
  right: -340px;
}

.AppFormWrapper .stepBar.stepAdminBar .step:last-child:before {
  left: -340px;
}

.AppFormWrapper .stepBar.stepAdminBar .step:first-child.current:before {
  background-color: #114df2;
}

.AppFormWrapper .stepBar.stepAdminBar .step.current:before {
  z-index: -1;
}

.AppFormWrapper .stepBar.stepAdminBar .step.step02:after {
  display: none;
}

.AppFormWrapper .stepBar.stepAdminBar .step.step02 .dot {
  background-color: #114df2;
}

.AppFormWrapper .createSection {
  position: relative;
  width: 100%;
  border: 1px dashed #114df2;
  background: #fff;
  margin-top: 90px;
  padding: 10px 0;
}

.AppFormWrapper .createSection.blank {
  min-height: 289px;
}

.AppFormWrapper .createSection .optionSection {
  background-image: url(./assets/media/bgsection.svg);
  position: absolute;
  display: block;
  top: -30px;
  left: 0;
  right: 0;
  margin: auto;
  width: 89px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.AppFormWrapper .createSection .optionSection a + a {
  margin-left: 10px;
}

.AppFormWrapper .applicantText {
  border-bottom: 1px solid #bfbfbf;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  text-transform: uppercase;
  padding: 0 0 12px;
  margin: 0 0 12px;
}

.AppFormWrapper .AppFormContent h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  text-align: left;
  margin: 0 0 10px;
  padding: 0;
}

.AppFormWrapper .AppFormContent h2.upperText {
  text-transform: uppercase;
}

.AppFormWrapper .AppFormContent h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  text-align: left;
  margin: 0 0 10px;
  padding: 0;
}

.AppFormWrapper .AppFormContent h3.noMg {
  margin: 0;
}

.AppFormWrapper .AppFormContent h3.medium {
  font-weight: 500;
}

.AppFormWrapper .AppFormContent h4 {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  text-transform: capitalize;
  margin: 0 0 10px;
  padding: 0;
}

.AppFormWrapper .AppFormContent h4 span {
  color: #f5222d;
}

.AppFormWrapper .AppFormContent h5 {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  margin: 0 0 5px;
  padding: 0;
}

.AppFormWrapper .AppFormContent ul {
  list-style-type: lower-alpha;
  margin: 0 0 50px 15px;
  padding: 0;
}

.AppFormWrapper .AppFormContent ol {
  margin: 0 0 50px 15px;
  padding: 0;
}

.AppFormWrapper .AppFormContent ul li,
.AppFormWrapper .AppFormContent ol li {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000;
}

.AppFormWrapper .AppFormContent ul li ol,
.AppFormWrapper .AppFormContent ol li ul {
  margin: 0 0 10px;
}

.AppFormWrapper .AppFormContent ol li ul {
  padding-left: 15px;
}

.AppFormWrapper .createSection ul {
  margin-bottom: 0;
}

.AppFormWrapper .AppFormFooter {
  padding: 30px 0 0;
}

.AppFormWrapper .fieldRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;
  width: calc(100% + 20px);
}

.AppFormWrapper .fieldWrap {
  width: 33.33%;
  padding: 0 10px;
  margin: 0 0 20px;
}

.AppFormWrapper .fieldWrap.fieldFull {
  width: 99.99%;
}

.AppFormWrapper label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
  display: block;
  margin: 0 0 5px;
  padding: 0;
}

.AppFormWrapper label.note {
  font-weight: 600;
}

.AppFormWrapper label .asterick {
  color: #f5222d;
  margin-left: 2px;
}

.AppFormWrapper input[type="text"],
.AppFormWrapper input[type="email"],
.AppFormWrapper textarea {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 8px 12px;
  margin: 0;
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 1);
}

.AppFormWrapper .form-select {
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 1);
}

.AppFormWrapper input ::placeholder {
  color: rgba(0, 0, 0, 0.85);
}

.AppFormWrapper input ::-moz-placeholder {
  color: rgba(0, 0, 0, 0.85);
}

.AppFormWrapper input ::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.85);
}

.AppFormWrapper input[type="text"].error-field,
.AppFormWrapper input[type="email"].error-field,
.AppFormWrapper textarea.error-field,
.AppFormWrapper .form-select.error-field {
  border-color: #f5222d;
}

.AppFormWrapper .form-check-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.AppFormWrapper .form-check-inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  min-height: 22px;
}

.AppFormWrapper .form-check-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  margin: 0;
  padding: 0;
}

.AppFormWrapper .form-check-input {
  width: 16px;
  height: 16px;
  position: relative;
  float: none;
  margin: 3px 6px 0 0;
  padding: 0;
}

.AppFormWrapper .form-check-input:checked {
  background-color: #114df2;
  border-color: #114df2;
}

.AppFormWrapper .form-check-inline + .form-check-inline {
  margin-left: 16px;
}

.AppFormWrapper .boxDetail {
  margin: 0 0 20px;
  padding: 20px 20px 0;
  border-radius: 8px;
  border: 1px dashed rgba(8, 42, 81, 0.5);
}

.AppFormWrapper .addMoreButton {
  border-radius: 4px;
  border: 1px dashed#114DF2;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #114df2;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.AppFormWrapper .addMoreButton img {
  margin: 0 10px 0 0;
}

.AppFormWrapper .affidavit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 45px;
}

.AppFormWrapper .affidavit .heading,
.AppFormWrapper .affidavit .text {
  font-weight: 600;
  line-height: 30px;
  color: #082a51;
  text-align: center;
}

.AppFormWrapper .affidavit .heading {
  font-size: 20px;
}

.AppFormWrapper .affidavit .text {
  font-size: 16px;
}

.AppFormWrapper .uploadBox {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
}

.AppFormWrapper .uploadBox.error-field {
  border-color: #f5222d;
}

.AppFormWrapper .uploadEmpty {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 74px;
  cursor: pointer;
}

.AppFormWrapper .uploadEmpty img {
  margin: 0 8px 0 0;
}

.AppFormWrapper .uploadFilled {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 74px;
  padding: 9px 8px;
}

.AppFormWrapper .uploadFilled .uploaded {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1890ff;
  width: calc(100% - 35px);
}

.AppFormWrapper .uploadFilled .uploaded img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  margin: 0 8px 0 0;
  cursor: pointer;
}

.AppFormWrapper .uploadFilled .uploaded span {
  display: block;
  cursor: pointer;
}

.AppFormWrapper .uploadFilled .uploadDel {
  margin: 0 5px;
  cursor: pointer;
}

.AppFormWrapper .signRow.fieldRow {
  justify-content: space-between;
  margin-top: 20px;
}

.AppFormWrapper .signRow.fieldRow .fieldWrap {
  width: 45%;
}

.AppFormWrapper .signField {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.AppFormWrapper .signField .uploadBox {
  width: calc(100% - 140px);
}

.AppFormWrapper .signField .uploadBox.uploaded {
  width: 100%;
}

.AppFormWrapper .signField .uploadBox.uploaded img {
  width: auto;
  height: 140px;
  margin: 0 auto;
}

.AppFormWrapper .buttonSign {
  background: #fff;
  border: 1px dashed #114df2;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #114df2;
  text-align: center;
  padding: 5px 15px;
  margin: 0;
  display: table;
  min-width: 120px;
}

.AppFormWrapper .AppFormContent {
  min-height: 592px;
}

.AppFormWrapper .AppFormFooter {
  border-top: 1px solid #bfbfbf;
  padding: 30px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.AppFormWrapper .AppFormFooter .appFootRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.AppFormWrapper .AppFormFooter .submit {
  background: #114df2;
  border: 1px solid #114df2;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  text-align: center;
  padding: 5px 15px;
  margin: 0;
  display: table;
  min-width: 120px;
}

.AppFormWrapper .AppFormFooter .reset {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #114df2;
  text-align: center;
  padding: 5px 15px;
  margin: 0;
  display: table;
  min-width: 120px;
}

.AppFormWrapper .AppFormFooter .back {
  background: #fff;
  border: 1px dashed #114df2;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #114df2;
  text-align: center;
  padding: 5px 15px;
  margin: 0 30px 0 0;
  display: table;
  min-width: 120px;
}

.AppFormWrapper .AppFormFooter .back.noMgRight {
  margin-right: 0;
}

.AppFormDL {
  margin: 0 77px;
}

.AppFormBar {
  background: #323639;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  margin: 0 0 20px;
}

.AppFormBar.noShadow {
  box-shadow: none;
}

.AppFormBar .title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  margin: 0;
  padding: 0;
}

.AppFormBar .buttons {
  display: flex;
  flex-direction: row;
}

.AppFormBar .buttons a + a {
  margin-left: 16px;
}

.AppFormBar .pagination {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
}

.AppFormBar .pagination .pageNum {
  padding: 0 0px 0 0;
  margin: 0 10px 0 0;
  /* box-shadow: 1px 0px 0px 0px #bdbdc7; */
}

.AppFormBar .pagination .pageNum .pageCurrent {
  /* background: #191b1c; */
  font-weight: normal;
  margin: 0px 5px;
  /* padding: 5px 15px;
  margin: 0 10px 0 0; */
}

.AppFormBar .pagination .pageNum .slash {
  margin: 0 10px 0 0;
}

.AppFormBar .pagination .pageZoom {
  padding: 0 0 0 30px;
}

.AppFormBar .pagination .pageZoom .zoomLevel {
  background: #191b1c;
  /* padding: 5px 15px;
  margin: 0 10px; */
  width: 50px;
  height: 50px;
  border: solid 1px #ccc;
}

.AppFormDL .appFormPages {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.AppFormDL .appFormPages .pageScrolls {
  margin: 0 80px 0 0;
}

.AppFormDL .pageCurrent .appCurrFooter {
  padding: 20px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AppFormDL .pageCurrent .appCurrFooter .submit {
  background: #114df2;
  border: 1px solid #114df2;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  text-align: center;
  padding: 5px 15px;
  margin: 0;
  display: table;
  min-width: 120px;
}

.AppFormDL .pageCurrent .appCurrFooter .back {
  background: #fff;
  border: 1px dashed #114df2;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #114df2;
  text-align: center;
  padding: 5px 15px;
  margin: 0 30px 0 0;
  display: table;
  min-width: 120px;
}

.sp20 {
  height: 20px;
}

/* Editor Wrapper */
.editorWrapper {
  margin: -20px 0 -24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* Editor Bar */
.editorWrapper .editorBar {
  width: 260px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

/* Editor Header */
.editorWrapper .editorBar .editorHeader {
  border-bottom: 1px solid #082a51;
  background: #f5f5f5;
  padding: 20px 10px;
}

.editorWrapper .editorBar .editorHeader h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #082a51;
  text-align: center;
  margin: 0;
  padding: 0;
}

.editorWrapper .editorBar .editorHeader h2.subtitle {
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.editorWrapper .editorBar .editorHeader h2.subtitle a {
  margin: 0 20px 0 0;
}

/* Editor Body */
.editorWrapper .editorBody {
  height: calc(100% - 158px);
}

.editorWrapper .editorBody .elementItem {
  background-color: #fff;
  border-bottom: 1px solid rgba(8, 42, 81, 0.5);
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #082a51;
  cursor: pointer;
}

.editorWrapper .editorBody .elementItem:hover {
  background-color: #f5f5f5;
}

.editorWrapper .editorBody .itemOptions {
  padding: 20px 10px;
}

.editorWrapper .editorBody .itemOption.pt10 {
  padding-top: 10px;
}

.editorWrapper .editorBody .itemOption + .itemOption {
  margin-top: 10px;
}

.editorWrapper .editorBody .itemOption.separator {
  background-color: rgba(8, 42, 81, 0.5);
  margin: 30px -10px 20px;
  padding: 0;
  height: 1px;
}

.editorWrapper .editorBody .itemOption + .itemOption.inlineOption {
  margin-top: 20px;
}

.editorWrapper .editorBody .itemOption label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
  margin: 0 0 10px;
  padding: 0;
}

.editorWrapper .editorBody .itemOption.inlineOption label {
  margin: 0 20px 0 0;
}

.editorWrapper .editorBody .itemOption.inlineOption label.w60 {
  width: 60px;
}

.editorWrapper .editorBody .itemOption.inlineOption label.w100 {
  width: 100px;
}

.editorWrapper .editorBody .itemOption.inlineOption {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editorWrapper .editorBody .itemOption.inlineOption .form-control {
  border: 1px solid rgba(8, 42, 81, 0.5);
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #082a51;
  padding: 5px 10px;
  margin: 0;
}

.editorWrapper .editorBody .itemOption > .form-control {
  border: 1px solid rgba(8, 42, 81, 0.5);
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  padding: 5px 10px;
  margin: 0;
}

.editorWrapper .editorBody .itemOption > textarea.form-control {
  line-height: 20px;
  padding: 10px;
}

.editorWrapper
  .editorBody
  .itemOption.inlineOption
  input[type="number"].form-control {
  padding: 0 0 0 10px;
  line-height: 30px;
}

.editorWrapper .editorBody .itemOption .itemProp,
.editorWrapper .editorBody .itemOption .itemAttr,
.editorWrapper .editorBody .itemOption .itemAllign,
.editorWrapper .editorBody .itemOption .itemSizeProp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.editorWrapper .editorBody .itemOption .itemAllign {
  align-items: center;
}

.editorWrapper .editorBody .itemOption .itemSizeProp {
  justify-content: space-between;
  margin: 0 0 20px;
}

.editorWrapper .editorBody .itemOption .itemProp,
.editorWrapper .editorBody .itemOption .itemAllign {
  border: 1px solid rgba(8, 42, 81, 0.5);
  border-radius: 6px;
  overflow: hidden;
}

.editorWrapper .editorBody .itemOption .itemProp .form-control,
.editorWrapper .editorBody .itemOption .itemAttr span,
.editorWrapper .editorBody .itemOption .itemAllign span {
  width: 25%;
  text-align: center;
  padding: 10px;
  margin: 0;
  border: none;
  border-radius: 0;
}

.editorWrapper .editorBody .itemOption .itemAttr.itemAllignAttr span {
  padding: 10px 0;
}

.editorWrapper .editorBody .itemOption .itemAllign span {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.editorWrapper .editorBody .itemOption .itemAllign span img {
  margin: 0 auto;
  max-height: 18px;
}

.editorWrapper .editorBody .itemOption .itemSizeProp span {
  width: 46px;
  height: 42px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid rgba(8, 42, 81, 0.5);
  background: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
  text-align: center;
  cursor: pointer;
}

.editorWrapper .editorBody .itemOption .itemSizeProp span.current {
  background-color: #114df2;
  border-color: #114df2;
  color: #fff;
}

.editorWrapper .editorBody .itemOption .itemSizeProp span.h1 {
  font-weight: 600;
  font-size: 20px;
}

.editorWrapper .editorBody .itemOption .itemSizeProp span.h1 {
  font-size: 18px;
}

.editorWrapper .editorBody .itemOption .itemSizeProp span.h1 {
  font-size: 16px;
}

.editorWrapper .editorBody .itemOption .itemProp .form-control {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #082a51;
  padding-top: 5px;
  padding-bottom: 5px;
}

.editorWrapper .editorBody .itemOption .itemProp .form-control + .form-control,
.editorWrapper .editorBody .itemOption .itemAllign span + span {
  border-left: 1px solid rgba(8, 42, 81, 0.5);
}

.editorWrapper .editorBody .itemOption .form-select {
  border-radius: 6px;
  border: 1px solid rgba(8, 42, 81, 0.5);
  padding: 6px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
}

.editorWrapper .editorBody .itemOption .itemAttr span {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #434343;
}

.editorWrapper .editorBody .itemOptionFooter {
  margin: 30px 0 0;
  padding: 20px 0 0;
  border-top: 1px solid rgba(8, 42, 81, 0.5);
}

.editorWrapper .editorBody .itemOptionFooter .addButton {
  border-radius: 2px;
  border: 1px dashed #d9d9d9;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #114df2;
  text-align: center;
  display: table;
  width: 100%;
  padding: 4px 7px;
}
.editorWrapper .editorBody .itemOptionFooter .edit-field-button,
.editorWrapper .editorBody .itemOptionFooter .cancel-edit-button,
.editorWrapper .editorBody .itemOptionFooter .delete-field-button {
  border-radius: 2px;
  border: 1px dashed #d9d9d9;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #114df2;
  text-align: center;
  /* display: table; */
  /* width: 100%; */
  padding: 4px 7px;
  flex: 1;
}
.editorWrapper .editorBody .itemOptionFooter .cancel-edit-button {
  margin-left: 10px;
}
.editorWrapper .editorBody .itemOptionFooter .delete-field-button {
  color: red;
}

.edit-field-button:after {
}

.edit-field-button:main,
.edit-field-button:focus {
  user-select: auto;
}

.edit-field-button:hover:not(:disabled) {
  color: "white";
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.edit-field-button:disabled {
  cursor: auto;
}

.edit-field-button:active {
  border: 1px dashed #d9d9d9;
  background: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset !important;
}

.cancel-edit-button:after {
}

.cancel-edit-button:main,
.cancel-edit-button:focus {
  user-select: auto;
}

.cancel-edit-button:hover:not(:disabled) {
  color: "white";
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.cancel-edit-button:disabled {
  cursor: auto;
}

.cancel-edit-button:active {
  border: 1px dashed #d9d9d9;
  background: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset !important;
}

.delete-field-button:after {
}

.delete-field-button:main,
.delete-field-button:focus {
  user-select: auto;
}

.delete-field-button:hover:not(:disabled) {
  color: "white";
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.delete-field-button:disabled {
  cursor: auto;
}

.delete-field-button:active {
  border: 1px dashed #d9d9d9;
  background: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset !important;
}

.addButton:after {
}

.addButton:main,
.addButton:focus {
  user-select: auto;
}

.addButton:hover:not(:disabled) {
  color: "white";
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.addButton:disabled {
  cursor: auto;
}

.addButton:active {
  border: 1px dashed #d9d9d9;
  background: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset !important;
}

.editorWrapper .editorBody .form-control + .addMoreButton {
  margin-top: 10px;
}

.editorWrapper .editorBody .addMoreButton {
  border-radius: 4px;
  border: 1px dashed#114DF2;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #114df2;
  padding: 2px 7px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 110px;
}

.editorWrapper .editorBody .addMoreButton img {
  margin: 0 5px 0 0;
}

.editorWrapper .editorBody .uploadButton {
  border-radius: 4px;
  border: 1px dashed#114DF2;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #114df2 !important;
  padding: 30px 12px !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.editorWrapper .editorBody .uploadButton img {
  width: 24px;
  margin: 0 0 7px;
}

.editorWrapper .editorBody .itemOptionGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 10px;
}

.editorWrapper .editorBody .itemOptionGroup .itemOption {
  width: calc(50% - 10px);
}

.editorWrapper .editorBody .itemOptionGroup .itemOption + .itemOption {
  margin-top: 0;
}

.editorWrapper .editorBody .itemOption .inputGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;
  padding: 0;
}

.editorWrapper .editorBody .itemOption .inputGroupWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid rgba(8, 42, 81, 0.5);
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  width: calc(100% - 32px);
  overflow: hidden;
}

.editorWrapper .editorBody .itemOption .inputGroupWrap span {
  background: #fafafa;
  border-right: 1px solid #d9d9d9;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  padding: 5px 0;
  margin: 0;
  width: 32px;
  height: 32px;
}

.editorWrapper .editorBody .itemOption .inputGroupWrap .form-control {
  border: none;
  border-radius: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  padding: 5px 10px;
  margin: 0;
  width: calc(100% - 32px);
}

.editorWrapper .editorBody .itemOption .inputGroup img {
  cursor: pointer;
}

.editorWrapper .editorBody .itemOption .form-check-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.editorWrapper
  .editorBody
  .itemOption
  .form-check-wrapper
  + .form-check-wrapper {
  margin-top: 10px;
}

.editorWrapper .editorBody .itemOption .form-check-inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  min-height: 22px;
}

.editorWrapper .editorBody .itemOption .form-check-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 1);
  margin: 0;
  padding: 0;
}

.editorWrapper .editorBody .itemOption .form-check-input {
  width: 16px;
  height: 16px;
  position: relative;
  float: none;
  margin: 3px 8px 0 0;
  padding: 0;
}

.editorWrapper .editorBody .itemOption .form-check-input:checked {
  background-color: #114df2;
  border-color: #114df2;
}

.editorWrapper .editorBody .itemOption .form-check-inline + .form-check-inline {
  margin-left: 16px;
}

.editorWrapper .editorBody .itemOption .form-check-vertical .form-check {
  margin: 0;
  padding: 0;
}

.editorWrapper
  .editorBody
  .itemOption
  .form-check-vertical
  .form-check
  + .form-check {
  margin: 0;
  padding: 0;
  margin-top: 5px;
}

/* Editor Footer */
.editorWrapper .editorBar .editorFooter {
  border-top: 1px solid #bfbfbf;
  padding: 30px 10px;
}

.editorWrapper .editorBar .editorFooter .btngroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editorWrapper .editorBar .editorFooter .buttonPrimary,
.editorWrapper .editorBar .editorFooter .buttonSecondary {
  border-radius: 4px;
  padding: 4px 15px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: table;
  width: calc(50% - 10px);
}

.editorWrapper .editorBar .editorFooter .buttonPrimary {
  border: 1px solid #114df2;
  background: #114df2;
  color: #fff;
}

.editorWrapper .editorBar .editorFooter .buttonSecondary {
  background-color: #fff;
  border: 1px dashed #114df2;
  color: #114df2;
}

.editorWrapper .editorBar .editorFooter .buttonPrimary.w100,
.editorWrapper .editorBar .editorFooter .buttonSecondary.w100 {
  width: 100%;
}

/* Editor App Form */
.editorWrapper .AppFormWrapper {
  width: calc(100% - 290px);
  margin: 30px 0 30px 30px;
}

.formStates.boxWhite {
  padding: 25px;
  margin: 0 0 40px;
}

.formStates .form-select + .rowCompanyStates {
  margin-top: 30px;
}

.formStates .rowCompanyStates {
  display: flex;
  flex-direction: column;
}

.formStates .colCompanyStates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 20px;
  margin: 0 0 10px;
  border-bottom: 1px solid #d9d9d9;
}

.formStates .colCompanyStates + .colCompanyStates {
  margin-top: 20px;
}

.formStates .infoCompanyStates {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formStates .infoCompanyStates img {
  margin: 0 10px 0 0;
}

.formStates .infoCompanyStates span {
  font-size: 18px;
  line-height: 22px;
  color: #082a51;
}

.formStates .numCompanyStates {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #114df2;
  text-align: right;
}

.minHeight330 {
  min-height: 330px;
}

.rolesList {
  margin: 0;
  padding: 0;
}

.rolesList li {
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  padding: 0;
}

.alertWrapper {
  padding: 20px;
}

.alert {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  padding: 9px 16px;
}

.alert.flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.alert.success-text {
  border-color: #b7eb8f;
  background-color: #f6ffed;
}

.alert.info-text {
  border-color: #91d5ff;
  background-color: #e6f7ff;
}

.alert.error-text {
  border-color: #ffccc7;
  background-color: #fff1f0;
}

.alert.warning-text {
  border-color: #ffe58f;
  background-color: #fffbe6;
}

.alert .alertTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  display: block;
  margin: 0 0 5px;
}

.alert .alertDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.alert .iconAlertClosed {
  display: table;
  margin: 5px 0 0 10px;
  cursor: pointer;
}

.alert .alertIconWrap,
.alert .alertIconWrapDesc {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.alert .alertIconWrap img {
  margin: 4px 10px 0 0;
}

.alert .alertIconWrapDesc img {
  margin: 2px 10px 0 0;
}

.alert .buttonSwap {
  border: 1px dashed #91d5ff;
  background: #e6f7ff;
  font-size: 10px;
  line-height: 20px;
  color: #1890ff;
  text-align: center;
  padding: 5px 10px;
  margin: -5px 0;
  display: table;
}

.alert .flexSwap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.alert .flexSwap.fullSwap {
  width: calc(100% - 20px);
}

.alert .swapClosed {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.alert .swapClosed .iconAlertClosed {
  margin-top: 0;
}

.alert .headSwap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 5px;
}

.alert .headSwap .alertTitle {
  margin: 0;
}

.error-input {
  border: 1px solid red !important;
}

.error-message {
  color: red;
  font-size: 12px;
}

.error-message-wrap {
  margin-top: -20px;
}

.password-strength-bar {
  height: 3px;
  width: 100%;
  background-color: #eee;
  border-radius: 5px;
  overflow: hidden;
}

.password-strength-meter {
  height: 100%;
  background-color: #4caf50;
  /* Green color */
  transition: width 0.3s;
}

/* Charts CSS 
-------------------------------------*/
.boxWhite.boxChart {
  padding: 20px 20px 0;
}
.boxChart select.form-select {
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  height: 36px;
  padding-top: 0;
  padding-bottom: 0;
  border-color: rgba(8, 42, 81, 0.5);
}
.rowChartHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
  padding: 0;
}
.rowChartHead h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #082a51;
  margin: 0;
  padding: 0;
}
.rowChartHead h3 b {
  font-weight: 500;
  font-size: 20px;
  margin: 0 5px 0 0;
}
.rowChartHead .nav-tabs {
  border: none;
}
.rowChartHead .nav-item + .nav-item {
  margin-left: 32px;
}
.rowChartHead .nav-tabs .nav-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
  border: none;
  border-bottom: 2px solid transparent;
  padding: 8px 0 6px;
  margin: 0;
}
.rowChartHead .nav-tabs .nav-link:hover,
.rowChartHead .nav-tabs .nav-link:focus {
  color: #114df2;
}
.rowChartHead .nav-tabs .nav-link.active {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #114df2;
  border-color: #114df2;
}
.boxChart .tab-pane {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #082a51;
}
.appBadge {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #e6f7ff;
  font-style: normal;
  color: #082a51;
  padding: 4px 8px;
  font-size: 14px;
  text-transform: capitalize;
}

.button-19 {
  border-radius: 2px;
  border: 1px dashed #d9d9d9;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #114df2;
  text-align: center;
  display: table;
  width: 100%;
  padding: 4px 7px;
}

.button-19:after {
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  color: "white";
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}

.button-19:active {
  border: 1px dashed #d9d9d9;
  background: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset !important;
}

.application-dropdown::after {
  display: none;
}

.page {
  margin: 0 auto;
}

.mandatory-text {
  display: flex;
  align-items: end;
}

.mandatory-text span {
  font-size: 12px;
  margin-left: 15px;
  color: red;
}
.date-picker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it stays on top of other elements */
  text-align: center;
}

.date-picker-heading {
  margin-bottom: 20px; /* Space below the heading */
}

.date-picker-container {
  position: relative;
}

/* Custom styles for the calendar popper to adjust its position */
.date-picker-popper {
  transform: translateX(-50%) !important;
  left: 50% !important;
  top: 5px !important; /* Space between input and calendar */
  z-index: 1000;
}

.disarea {
  display: flex;
  width: 100%;
}

.disarea > div {
  flex: auto;
}
.discontainer {
  display: block;
  margin: 0 30px;
  border: 1px solid #cfcfcf;
  max-width: 300px;
  position: relative;
}

.discontainer .title {
  padding: 14px;
  background: #e7e7e7;
  color: #082a51;
  margin-bottom: 20px;
}

.discontainer .disform {
  padding: 14px;
}

.discontainer .boardFilter {
  display: block;
}

.discontainer .boardFilter .form-check-inline {
  padding: 10px 0;
}

.discontainer .boardFilter .form-check-inline .label {
  display: block;
  width: 100%;
}

.discontainer .boardFilter .form-check-inline + .form-check-inline {
  margin-left: 0px;
}

.discontainer .mainlabel {
  font-size: 16px;
  color: #000;
  font-weight: normal;
}

.discontainer textarea {
  margin-top: 0px;
  width: 100%;
  border: 1px solid #323639;
  height: 100px;
  border-radius: 5px;
}

.mt {
  margin-top: 30px;
}

.discontainer .buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #cfcfcf;
  padding: 10px;
}

.discontainer .buttonGroup .AppFormButton {
  background: #114df2;
  border: 1px solid #114df2;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  padding: 5px 15px;
  margin: 0 auto;
  width: 45%;
  text-align: center;
}

.discontainer .buttonGroup .AppFormButton2 {
  background: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  padding: 5px 15px;
  margin: 0 auto;
  width: 45%;
  text-align: center;
}

/* Spinner styles */
.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Sticky FormHeader styling */
.stickyFormHeader {
  position: sticky;
  top: 50px;
  z-index: 1000; /* Ensure it stays above other content */
  background-color: white; /* Set background to avoid transparency when scrolling */
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: add shadow for effect */
}
.react-tel-input {
  border-radius: 5px !important;
  height: 42px !important;
}
.react-tel-input .form-control {
  width: 100% !important;
  height: 100% !important;
}
.form-control:focus {
  box-shadow: none !important;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.react-datepicker-wrapper .react-datepicker__input-container .date-range-input {
  border: 1px solid #082a5180;
  font-size: 14px;
  color: #082a51;
  border-radius: 4px;
  min-width: 190px;
}
