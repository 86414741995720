.emp-details-wrapper {
}
.heading-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.heading-wrapper p {
  font-family: Roboto;
  font-size: 25px;
  font-weight: 500;
  line-height: 29.3px;
  text-align: left;
  margin: 0;
  color: #082a51;
}
.c-p {
  cursor: pointer;
}
.download-btn {
  height: 32px;
  width: 124px;
  border-radius: 4px;
  color: white;
  background-color: #114df2;
  font-weight: 400;
  font-size: 14px;
  border-color: #114df2;
  box-shadow: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-btn {
  color: #114df2;
  font-weight: 400;
  font-size: 14px;
}
.user-details-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: white;
  border-radius: 8px;
  gap: 20px;
  flex-wrap: wrap;
  box-shadow: 0px 8px 10px 0px #33276b0f;
}
.user-details-section .user-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.user-details-section .user-name img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.user-details-section .user-name .name-phone p {
  font-size: 21px;
  font-weight: 400;
  color: #082a51;
  margin-bottom: 6px;
}
.user-details-section .user-name .name-phone p:nth-child(2) {
  font-size: 16px;
  opacity: 0.5;
  margin: 0;
}
.user-details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  flex-wrap: wrap;
}
.user-detail-item p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #082a51;
  opacity: 0.5;
}
.user-detail-item p:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  color: #082a51;
  opacity: 1;
}

.previous-companies-wrapper {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 8px 10px 0px #33276b0f;
  padding: 20px 0;
  margin-top: 20px;
  margin-bottom: 30px;
}

.previous-heading {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #082a51;
  margin-left: 20px;
}

.previous-companies-wrapper table {
  width: 100%;
}
.previous-companies-wrapper table thead {
  background: #f9fafb;
  border-bottom: 1px solid #eaecf0;
}
.previous-companies-wrapper table thead th {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #082a5180;
  padding: 8px 0;
}

.previous-companies-wrapper table thead th:first-child {
  padding-left: 20px;
}
.previous-companies-wrapper table thead th:last-child {
  padding-right: 20px;
}
.previous-companies-wrapper table tbody tr td {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #082a51;
  padding: 8px 0;
}
.previous-companies-wrapper table tbody tr td:first-child {
  padding-left: 20px;
}
.previous-companies-wrapper table tbody tr td:last-child {
  padding-right: 20px;
}
