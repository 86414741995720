.user-name {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}
.user-ip {
  color: var(--character-secondary-45, rgba(8, 42, 81, 0.5));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}
.profile-pic-wrapper {
  width: 70px;
  margin-right: 10px;
}

.profile-pic-wrapper img {
  width: 100%;
}

.modal-content-row-2 > div {
  flex: 1;
}
.modal-content-row-2 > div:nth-child(1) {
  flex: 2;
}
.personal-info-title {
  color: var(--character-secondary-45, rgba(8, 42, 81, 0.5));
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.personal-info-value {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.model-content-row-3-title {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.modal-content-row-3-data {
  background: var(--geek-blue-1, #f0f5ff);
  padding: 10px 20px;
  border-radius: 10px;
}
.app-form-title {
  color: var(--character-secondary-45, rgba(8, 42, 81, 0.5));
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.app-form-value {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.model-content-row-4-title {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.modal-content-row-4-header {
  background: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  padding: 15px;
}
.modal-content-row-4-data {
  padding: 15px;
}
.modal-content-row-4-header > div,
.modal-content-row-4-data > div {
  flex: 1;
}
.modal-content-row-4-table-heading {
  color: var(--character-secondary-45, rgba(8, 42, 81, 0.5));
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.modal-content-row-4-table-value {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.reason-field-wrapper textarea {
  width: 100%;
  border: solid 1px #ccc;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 12px;
  color: #5f5e5e;
}
.delete-modal-title {
  color: var(--day-break-blue-10, #082a51);
  text-align: center;
  font-family: Roboto;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.delete-employee-button {
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  background: var(--primary-10, #114df2);
  border: none;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.cancel-button {
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  background: var(--primary-10, #fff);
  border: dashed 1px #ccc;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #114df2);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.delete-message {
  color: var(--day-break-blue-10, #082a51);
  font-family: Roboto;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
